import { useEffect, useState } from "react";
import {
  Container,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./navbar.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie"; // Import js-cookie for cookie management

const NavBar = () => {
  const { cartList } = useSelector((state) => state.cart);
  const [expand, setExpand] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  // Check if user is logged in
  const isLoggedIn = !!Cookies.get("access_token"); // Check for access token

  // Logout function
  const handleLogout = () => {
    Cookies.remove("access_token"); // Remove access token from cookies
    navigate("/"); // Redirect to home page after logout
  };

  // fixed Header
  function scrollHandler() {
    if (window.scrollY >= 100) {
      setIsFixed(true);
    } else if (window.scrollY <= 50) {
      setIsFixed(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler); // Cleanup on unmount
    };
  }, []);

  return (
    <Navbar
      fixed="top"
      expand="md"
      className={isFixed ? "navbar fixed" : "navbar"}
    >
      <Container className="navbar-container">
        {/* Media cart and toggle */}
        <div className="d-flex justify-content-between w-100">
          <Navbar.Brand to="/">
            <Link to="/" className="navbar-brand">
              {/* <ion-icon name="bag"></ion-icon> */}
              <img
                src="/biocommerceLogo.svg"
                alt="Biocommerce Logo"
                className="navbar-logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => {
              setExpand(expand ? false : "expanded");
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end">
            {isLoggedIn ? ( // Conditional rendering based on login status
              <>
                {/* <Nav.Item>
                  <div className="user-info">
                    <img
                      src="path/to/avatar.png"
                      alt="User Avatar"
                      className="avatar"
                    />
                    <span className="username">Username</span>{" "}
                  </div>
                </Nav.Item> */}
                <Nav.Item>
                  <NavLink
                    to="/"
                    className="navbar-link"
                    onClick={handleLogout}
                  >
                    Logout
                  </NavLink>
                </Nav.Item>
              </>
            ) : (
              <>
                <Nav.Item>
                  <NavLink
                    to="/register"
                    className="navbar-link btn btn-secondary"
                    onClick={() => setExpand(false)}
                  >
                    Registrate
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink
                    to="/login"
                    className="navbar-link btn btn-secondary"
                    onClick={() => setExpand(false)}
                  >
                    Inicia Sesion
                  </NavLink>
                </Nav.Item>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
