import React from "react";
import "./style.css";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="footer-row">
          <Col md={4} sm={6} className="box">
            <div className="logo">
              {/* <ion-icon name="bag"></ion-icon> */}
              <img
                src="/biocommerceLogo.svg"
                alt="Biocommerce Logo"
                style={{
                  width: "56.5645%",
                  height: "auto",
                  filter: "invert(1)",
                }} // Added filter to invert colors
              />
            </div>
            <h5>Construyamos juntos el mercado de la bioeconomía</h5>
          </Col>
          <Col md={4} sm={6} className="box pt-4">
            <h2>Ayuda</h2>
            <ul>
              <li>Contactanos</li>
              <li>Preguntas Frecuentes</li>
            </ul>
          </Col>
          <Col md={4} sm={6} className="box pt-4">
            <h2>Politicas Legales</h2>
            <ul>
              <li>Politicas de Privacidad </li>
              <li>Términos y Condiciones</li>
            </ul>
          </Col>
          {/* <Col md={3} sm={5} className="box">
            <h2>Contact Us</h2>
            <ul>
              <li>
                70 Washington Square South, New York, NY 10012, United States{" "}
              </li>
              <li>Email: uilib.help@gmail.com</li>
              <li>Phone: +1 1123 456 780</li>
            </ul>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
